import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../store';
import http from '../../../../infra/common/httpService';
import { DELETE_MISSION } from '../../../../infra/common/httpService/constants';

export interface Mission {
  uuid: string;
  mission: string;
  is_direct_return: boolean;
  is_reversed_return: boolean;
  scheduled_time: string;
}

interface DeviceListMissionListProps {
  setValue: (value: string) => void;
  setSelectedMission: (value: Mission) => void;
  setIsEditMissionActive: (value: boolean) => void;
}

const DeviceListMissionList: FC<DeviceListMissionListProps> = (props) => {
  const selectedUuidDevice = useAppSelector(
    (state) => state.vehicles.selectedUuidDevice?.uuid
  );

  const { setValue, setSelectedMission, setIsEditMissionActive } = props;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [metricsList, setMetricsList] = useState<Mission[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const getMissionList = async (
    selectedUuidDevice: string,
    limit: number,
    page: number
  ) => {
    setLoading(true);
    setError(null);
    try {
      const path = `employee_tasks/scheduled/${selectedUuidDevice}`;
      const query = {
        limit,
        page,
      };

      const response = await http.get(path, { params: query });

      setMetricsList((prev) => [...prev, ...response.data.items]);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteMission = async (task_uuid: string): Promise<any> => {
    try {
      await http.delete(`${DELETE_MISSION}/${task_uuid}/delete`);
      if (selectedUuidDevice) {
        getMissionList(selectedUuidDevice, limit, page);
      }
    } catch (error: unknown) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedUuidDevice) {
      getMissionList(selectedUuidDevice, limit, page);
    }
  }, [selectedUuidDevice, limit, page]);

  const handleEditMission = (mission: Mission) => {
    setSelectedMission(mission);
    setIsEditMissionActive(true);
    setValue('1');
  };

  let content;
  if (loading && page === 1) {
    content = (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='200px'
      >
        <CircularProgress />
      </Box>
    );
  } else if (error) {
    content = <Typography color='error'>{error}</Typography>;
  } else {
    content = (
      <List>
        {metricsList.map((mission) => {
          return (
            <ListItem key={mission.uuid}>
              <ListItemText
                primary={`Миссия: ${mission.mission}`}
                secondary={`Запланировано на: ${new Date(
                  mission.scheduled_time
                ).toLocaleString()}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='edit'
                  style={{ marginRight: '10px' }}
                  onClick={() => handleEditMission(mission)} // Вызов handleEditMission с текущей миссией
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => deleteMission(mission.uuid)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <Box>
      <Typography variant='h6' gutterBottom>
        Список миссий
      </Typography>
      {content}
    </Box>
  );
};

export default DeviceListMissionList;
