import { ChangeEvent, DragEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import { useAppSelector } from '../../../../store';
import classes from '../../../styles/mission.module.css';
import { setMissionText } from '../../../../store/vehicle/vehicle.slice';
import { SEND_NEW_MISSION } from '../../../../infra/common/httpService/constants';
import DeviceListMissionList, { Mission } from './DeviceListMissionList';
import http from '../../../../infra/common/httpService';
import TabPanel from './TabPanel'; // Импортируйте ваш компонент

interface DeviceListType {
  isOpenMissionPopup: boolean;
  setIsOpenMissionPopup: (e: boolean) => void;
}

const DeviceListMission = (props: DeviceListType) => {
  const { isOpenMissionPopup, setIsOpenMissionPopup } = props;
  const selectedUuidDevice = useAppSelector(
    (state) => state.vehicles.selectedUuidDevice?.uuid
  );
  const [isDirectReturn, setIsDirectReturn] = useState(false);
  const [isReverseReturn, setIsReverseReturn] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);
  const [isEditMissionActive, setIsEditMissionActive] =
    useState<boolean>(false);
  const [value, setValue] = useState('1');
  const missionText = useAppSelector((state) => state.vehicles.missionText);
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleFileUpload = (file: File): void => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const result = e.target?.result as string;
      dispatch(setMissionText(result));
    };

    reader.readAsText(file);
  };

  const handleFileInput = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleDrop = (event: DragEvent<HTMLTextAreaElement>): void => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLTextAreaElement>): void => {
    event.preventDefault();
  };

  const sendMission = async (
    uuid: string,
    mission: string,
    is_direct_return: boolean,
    is_reversed_return: boolean,
    scheduled_time: Dayjs | null
  ): Promise<any> => {
    const payload = {
      uuid,
      mission,
      is_direct_return,
      is_reversed_return,
      scheduled_time: scheduled_time ? scheduled_time.toISOString() : '',
    };

    try {
      const response: AxiosResponse = await http.post(
        SEND_NEW_MISSION,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const saveEditedMission = async (): Promise<void> => {
    if (!selectedMission || !selectedUuidDevice) {
      console.error('Selected mission or device UUID is missing.');
      return;
    }

    const payload = {
      uuid: selectedUuidDevice,
      mission: missionText,
      is_direct_return: isDirectReturn,
      is_reversed_return: isReverseReturn,
      scheduled_time: selectedDate ? selectedDate.toISOString() : '',
    };

    try {
      const response: AxiosResponse = await http.post(
        `/scheduled/${selectedMission.uuid}/edit`,
        { payload_mission: payload }
      );
      setIsEditMissionActive(false);
    } catch (error) {
      console.error('Error saving the edited mission:', error);
    }
  };

  useEffect(() => {
    if (selectedMission) {
      dispatch(setMissionText(selectedMission.mission));
      setIsDirectReturn(selectedMission.is_direct_return);
      setIsReverseReturn(selectedMission.is_reversed_return);
      setSelectedDate(
        selectedMission.scheduled_time
          ? dayjs(selectedMission.scheduled_time)
          : null
      );
    }
  }, [selectedMission, dispatch]);

  const handleCleanUp = () => {
    dispatch(setMissionText(''));
    setIsDirectReturn(false);
    setIsReverseReturn(false);
    setSelectedDate(null);
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={isOpenMissionPopup}
      onClose={() => setIsOpenMissionPopup(false)}
      aria-labelledby='media-list-title'
      aria-describedby='media-list-description'
    >
      <Box className={classes.modalContainer}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <IconButton
            onClick={() => {
              setIsOpenMissionPopup(false);
              if (isEditMissionActive) {
                handleCleanUp();
              }
              setIsEditMissionActive(false);
            }}
            className={classes.modalCloseIcon}
          >
            <CloseIcon />
          </IconButton>
          <Tabs value={value} onChange={handleChange} aria-label='Tabs example'>
            <Tab label='Create Mission' value='1' />
            <Tab label='Missions list' value='2' />
          </Tabs>
          <TabPanel value={value} index='1'>
            <Typography variant='body1'>
              Device uuid: {selectedUuidDevice}
            </Typography>
            <Box>
              <FormGroup>
                <FormControlLabel
                  checked={isDirectReturn}
                  control={
                    <Checkbox
                      onClick={() => setIsDirectReturn(!isDirectReturn)}
                    />
                  }
                  label='Direct return'
                />
                <FormControlLabel
                  checked={isReverseReturn}
                  control={
                    <Checkbox
                      onClick={() => setIsReverseReturn(!isReverseReturn)}
                    />
                  }
                  label='Reverse return'
                />
              </FormGroup>
            </Box>
            <DateTimePicker
              sx={{ marginTop: '10px' }}
              label='Select date and time'
              value={selectedDate}
              onChange={(e) => setSelectedDate(e)}
            />
            <Box>
              <textarea
                value={missionText}
                readOnly={isReadOnly}
                rows={10}
                cols={100}
                onDrop={handleDrop}
                onChange={(e) =>
                  !isReadOnly ? dispatch(setMissionText(e.target.value)) : null
                }
                onDragOver={handleDragOver}
                className={classes.missionTextarea}
              />
              <Box className={classes.missionContainer}>
                <Box className={classes.missionInputWrapper}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<UploadIcon />}
                  >
                    {t('mission.uploadTXT')}
                  </Button>
                  <input
                    className={classes.missionInput}
                    type='file'
                    accept='.txt'
                    onChange={handleFileInput}
                  />
                  <Button
                    sx={{ margin: '0 10px' }}
                    variant='contained'
                    onClick={() => setIsReadOnly(!isReadOnly)}
                    color='primary'
                  >
                    {isReadOnly ? 'Edit mission' : 'Save edit'}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() =>
                      missionText && selectedUuidDevice && selectedDate
                        ? sendMission(
                            selectedUuidDevice,
                            missionText,
                            isDirectReturn,
                            isReverseReturn,
                            selectedDate
                          )
                        : null
                    }
                    color='primary'
                  >
                    {isEditMissionActive ? 'Save mission' : 'Send mission'}
                  </Button>
                </Box>

                <Box className={classes.missionSendAndStartContainer}>
                  <Button
                    variant='text'
                    color='inherit'
                    onClick={() => {
                      handleCleanUp();
                      setIsEditMissionActive(false);
                    }}
                  >
                    {t('mission.clearMission')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index='2'>
            <DeviceListMissionList
              setValue={setValue}
              setSelectedMission={setSelectedMission}
              setIsEditMissionActive={setIsEditMissionActive}
            />
          </TabPanel>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeviceListMission;
