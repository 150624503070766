import { Dispatch } from '@reduxjs/toolkit';
import SOCKET_URL from './constants';
import { getDataFromLocalStorage } from '../../../view/common/router/helpers';
import { IAuthData } from '../../../view/common/router/types';
import { CommandType, SOCKET_STATES } from '../../../domain/socket';
import {
  setSocket,
  setVehicleMetrics,
  setVehicleImages,
  setSocketStatus,
} from '../../../store/socket/socket.slice';
import {
  setNavigateToViewer,
  setVehicleConnectionStatus,
} from '../../../store/vehicle/vehicle.slice';

let socket: WebSocket | null = null;

export const socketConnect = async (
  vehicleUuid: string,
  viewType: string,
  dispatch: Dispatch
): Promise<void> => {
  const authData: IAuthData | null = getDataFromLocalStorage('auth');

  if (vehicleUuid && authData) {
    socket = new WebSocket(
      `${SOCKET_URL}${vehicleUuid}?ACCESS_TOKEN=${authData.accessToken}&role=${viewType}`
    );

    socket.onopen = (): void => {
      if (socket) {
        if (socket.readyState === SOCKET_STATES.OPEN) {
          dispatch(setSocket(socket));
        }
      }
    };
    socket.onmessage = (event): void => {
      const message: any = JSON.parse(event.data);
      if (message.status === 'error' && message.data[0]?.code === 403) {
        // const newToken = await refreshAccessToken();
        // axios.defaults.headers.common[ACCESS_TOKEN_HEADER] = newToken;
        socketConnect(vehicleUuid, viewType, dispatch);
        return;
      }

      if (message.data?.code === 403) {
        dispatch(setNavigateToViewer(true));
      }

      switch (message.type) {
        case 'metrics':
          dispatch(setVehicleMetrics(JSON.parse(message.metrics)));
          break;
        case 'vehicle_images':
          console.log(message.details, 'DETAILES');
          dispatch(
            setVehicleImages({
              image: message.details.img,
              camera: message.details.num_camera,
            })
          );
          break;
        case 'command_response':
          // console.log(message.details.command.tractor_controls);
          break;
        default:
      }
      if (message.type === 'command_response') {
        switch (message.details.type_task) {
          case 'disconnected':
            dispatch(setSocketStatus('disconnected'));
            dispatch(setVehicleConnectionStatus(false));
            break;
          case 'connected':
            dispatch(setSocketStatus('connected'));
            dispatch(setVehicleConnectionStatus(true));
            break;
          default:
        }
      }
    };
  }
};

export const socketSend = async (
  command: CommandType,
  timeProcessing: number
): Promise<void> => {
  command.time_processing.frontend_sent = Date.now() / 1000 + timeProcessing;
  if (socket && socket.readyState === SOCKET_STATES.OPEN) {
    await socket.send(JSON.stringify(command));
  }
};

export const socketDisconnect = async (): Promise<void> => {
  if (socket) {
    await socket.close();
    socket = null;
  }
};

// if (message.status === 'error' && message.data[0].code === 403) {
//   const newToken = await refreshAccessToken();
//   axios.defaults.headers.common[ACCESS_TOKEN_HEADER] = newToken;
//   connectWebSocket(newToken);
//   return;
// }
