import { VEHICLE_TYPES } from '../../../domain/vehicle';

export const gearsCar: string[] = ['Park', 'Reverse', 'Neutral', 'Drive'];
export const gearsTractorKHTI: string[] = ['Drive', 'Park', 'Reverse'];
export const gearsBoat: string[] = ['Reverse', 'Neutral', 'Drive'];
export const detectNulls = (
  {
    right_caterpillar,
    left_caterpillar,
    acceleration,
    trawl,
    accelerator,
    brake,
    steering_wheel_position,
    move,
  }: any,
  vehicleType: VEHICLE_TYPES
): boolean => {
  if (vehicleType === VEHICLE_TYPES.TRACTOR) {
    return (
      acceleration === 0 &&
      trawl === 0 &&
      left_caterpillar === 0 &&
      right_caterpillar === 0
    );
  }

  if (
    vehicleType === VEHICLE_TYPES.CAR ||
    vehicleType === VEHICLE_TYPES.TRACTOR_GEMINI
  ) {
    return accelerator === 0 && brake === 0 && steering_wheel_position === 0;
  }

  if (vehicleType === VEHICLE_TYPES.TRACTOR_KHTI) {
    return move === 0;
  }

  if (vehicleType === VEHICLE_TYPES.BOAT) {
    return accelerator === 0 && steering_wheel_position === 0;
  }
  return false;
};

const formatAxesCarBoatControl = (axe: number): number =>
  Math.round(axe * 20) / 20;
const formatAxes = (axe: number): number => Math.round(axe * 10) / 10;
const setAcceleration = (l2: boolean, r2: boolean): 1 | 0 => {
  if (l2) {
    return 1;
  }

  return r2 ? 1 : 0;
};

export const normalizeDeg = (gamepadWheel: number) => {
  return gamepadWheel * 450;
};

let lastMoveKhtiCommand = 0;

export const setKhtiTractorMove = (
  arrowUp: boolean,
  arrowDown: boolean,
  l2: boolean,
  r2: boolean
) => {
  if (arrowUp) {
    lastMoveKhtiCommand = 1;
    return { move: lastMoveKhtiCommand };
  }
  if (arrowDown) {
    lastMoveKhtiCommand = -1;
    return { move: lastMoveKhtiCommand };
  }
  if (l2 || r2) {
    lastMoveKhtiCommand = 0;
    return { move: lastMoveKhtiCommand };
  }
  return { move: lastMoveKhtiCommand };
};

const setVehicleDirection = (forward: boolean, back: boolean): number => {
  if (forward) {
    return 1;
  }

  if (back) {
    return -1;
  }
  return 0;
};

let gamepadGlobal = 0;
let doubleClickGamepad = false;

const trawlLastCommands = (l1: boolean, r1: boolean): number => {
  if (l1 && r1) {
    if (doubleClickGamepad) {
      return gamepadGlobal;
    }
    doubleClickGamepad = true;
    gamepadGlobal *= -1;
    return gamepadGlobal;
  }
  if (l1) {
    gamepadGlobal = -1;
    doubleClickGamepad = false;
    return -1;
  }
  if (r1) {
    gamepadGlobal = 1;
    doubleClickGamepad = false;
    return 1;
  }
  return 0;
};

let acceleration = 0;
let commandIsSended = false;
const toggleAcceleration = (
  share: boolean,
  l2: boolean,
  r2: boolean,
  blurState: boolean | undefined,
  resetControl: boolean | undefined
) => {
  if (share && !commandIsSended && !blurState) {
    acceleration = acceleration === 0 ? 1 : 0;
    commandIsSended = true;
  }

  if (!share) {
    commandIsSended = false;
  }

  if (l2 || r2) {
    acceleration = 0;
  }

  if (blurState || resetControl) {
    acceleration = 0;
  }
  return acceleration;
};
let brake = 0;
export const setGeminiTractorBrake = (options: boolean) => {
  if (options) {
    brake = brake === 0 ? 1 : 0;
  }

  return { brake };
};

export const formatPedals = (value: number): number =>
  Math.round(((1 - value) / 2) * 10) / 10;

export const setDataCommandBySteeringWheel = async (
  vehicleType: VEHICLE_TYPES,
  buttons: readonly GamepadButton[],
  axes: readonly number[]
): Promise<any> => {
  if (vehicleType === VEHICLE_TYPES.BOAT) {
    return {
      accelerator: formatPedals(axes[2]),
      steering_wheel_position: formatAxesCarBoatControl(axes[0]),
    };
  }

  if (vehicleType === VEHICLE_TYPES.CAR) {
    const steeringWheelPosition: number = axes[0];
    return {
      accelerator: formatPedals(axes[2]),
      brake: formatPedals(axes[5]),
      steering_wheel_position: steeringWheelPosition,
    };
  }

  if (vehicleType === VEHICLE_TYPES.TRACTOR_GEMINI) {
    const steeringWheelPosition: number = axes[0];
    return {
      accelerator: formatPedals(axes[2]),
      brake: formatPedals(axes[5]),
      steering_wheel_position: steeringWheelPosition,
    };
  }
  return null;
};

export const setDataCommandByGamepad = async (
  vehicleType: VEHICLE_TYPES,
  buttons: readonly GamepadButton[],
  axes: readonly number[],
  blurState?: boolean | undefined,
  resetControl?: boolean | undefined
): Promise<any> => {
  if (vehicleType === VEHICLE_TYPES.DRONE) {
    return {
      velocity_y: formatAxes(axes[2]),
      velocity_x: formatAxes(axes[3]) * -1,
      yaw_rate: formatAxes(axes[0]),
      velocity_z: formatAxes(axes[1]),
    };
  }

  if (vehicleType === VEHICLE_TYPES.BOAT) {
    return {
      accelerator: formatAxesCarBoatControl(buttons[7].value),
      steering_wheel_position: formatAxesCarBoatControl(axes[0]),
    };
  }

  if (vehicleType === VEHICLE_TYPES.CAR) {
    const steeringWheelPosition: number = axes[0];
    return {
      accelerator: formatAxesCarBoatControl(buttons[7].value),
      brake: formatAxesCarBoatControl(buttons[6].value),
      steering_wheel_position: steeringWheelPosition,
    };
  }

  if (vehicleType === VEHICLE_TYPES.TRACTOR_GEMINI) {
    const steeringWheelPosition: number = formatAxesCarBoatControl(axes[0]);
    return {
      accelerator: formatAxesCarBoatControl(buttons[7].value),
      brake: formatAxesCarBoatControl(buttons[6].value),
      steering_wheel_position:
        steeringWheelPosition === 0.05 || steeringWheelPosition === -0.05
          ? 0
          : steeringWheelPosition,
    };
  }

  if (vehicleType === VEHICLE_TYPES.TRACTOR) {
    return {
      left_caterpillar: formatAxes(axes[1] * -1),
      right_caterpillar: formatAxes(axes[3] * -1),
      acceleration: setAcceleration(buttons[6].pressed, buttons[7].pressed),
      trawl: trawlLastCommands(buttons[4].pressed, buttons[5].pressed),
    };
  }

  if (vehicleType === VEHICLE_TYPES.TRACTOR_KHTI) {
    return {
      rotate: setVehicleDirection(buttons[15].pressed, buttons[14].pressed),
      trawl: trawlLastCommands(buttons[4].pressed, buttons[5].pressed),
      acceleration: toggleAcceleration(
        buttons[8].pressed,
        buttons[6].pressed,
        buttons[7].pressed,
        blurState,
        resetControl
      ),
    };
  }
  return null;
};
