import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import {
  connectToVehicle,
  createArchive,
  deleteStreamingPhotos,
  deleteStreamingRecording,
  disconnectFromVehicle,
  getArchive,
  getArchiveList,
  getVehicleList,
  getVehicleListMetrics,
  getVehicleStatus,
  listStreamingPhotos,
  listStreamingRecordings,
  listStreamingSlides,
  vehicleIsCanConnect,
} from './vehicle.thunks';
import {
  CamerasState,
  IArchive,
  ISelectedPhoto,
  IStreamingPhoto,
  IStreamingSlide,
  Vehicle,
  VehicleMetrics,
  VehiclesListResponse,
} from '../../domain/vehicle';

export interface VehicleState {
  vehiclesListLoading: boolean;
  vehiclesList: Vehicle[] | null;
  vehiclesListError: string | null;
  vehiclesListMetricsLoading: boolean;
  vehiclesListMetrics: VehicleMetrics[] | null;
  vehiclesListMetricsError: string | null;
  connectToVehicleLoading: boolean;
  connectToVehicleSuccess: boolean;
  connectToVehicleError: string | null;
  disconnectFromVehicleLoading: boolean;
  disconnectFromVehicleSuccess: boolean;
  disconnectFromVehicleError: string | null;
  vehiclesListQuantity: number;
  vehiclesStatusLoading: boolean;
  activeVehicle: Vehicle | null;
  vehiclesStatusError: string | null;
  engineRunning: boolean;
  manualControlRunning: boolean;
  activeKeys: string[];
  gamepadRunning: boolean;
  gamepad: Gamepad | null;
  gamepadIndex: number | null;
  mapLayerType: string;
  metricsInterval: number;
  listRecordingsLoading: boolean;
  listRecordings: string[];
  listRecordingsError: string | null;
  listRecordingsTotal: number;
  deleteRecordingLoading: boolean;
  deleteRecordingError: string | null;
  cameraResolution: { [key: string]: string };
  listPhotosLoading: boolean;
  listPhotos: IStreamingPhoto[];
  listPhotosError: string | null;
  listPhotosTotal: number;
  listSlidesLoading: boolean;
  listSlides: IStreamingSlide[];
  listSlidesError: string | null;
  listSlidesTotal: number;
  listArchivesLoading: boolean;
  listArchives: IArchive[];
  listArchivesError: string | null;
  getArchiveLoading: boolean;
  archive: null | IArchive;
  getArchiveError: string | null;
  createArchiveError: string | null;
  listArchivesTotal: number;
  deletePhotosLoading: boolean;
  deletePhotosError: string | null;
  pinnedMap: boolean;
  mapYPosition: number;
  controller: string | null;
  showSelectController: boolean;
  mediaList: {
    selectedPhotos: ISelectedPhoto[];
    photosStartDate: string | null;
    photosEndDate: string | null;
    photosLimit: number;
    photosPage: number;
    slidesStartDate: string | null;
    slidesEndDate: string | null;
    selectedSlides: ISelectedPhoto[];
    slidesLimit: number;
    slidesPage: number;
    recordingsLimit: number;
    recordingsPage: number;
    fullScreenMode: boolean;
    fullScreenImageIndex: number;
  };
  selectedUuidDevice: string | null;
  listMapDragged: boolean;
  vehicleIsCanConnect: boolean;
  vehicleIsCanConnectError: string | null;
  navigateToViewer: boolean;
  isMissionStarted: boolean;
  missionText: string;
  camerasState: CamerasState[];
}

const initialState: VehicleState = {
  vehiclesListLoading: false,
  vehiclesList: null,
  vehiclesListError: null,
  vehiclesListMetricsLoading: false,
  vehiclesListMetrics: null,
  vehiclesListMetricsError: null,
  connectToVehicleLoading: false,
  connectToVehicleSuccess: false,
  connectToVehicleError: null,
  disconnectFromVehicleLoading: false,
  disconnectFromVehicleSuccess: false,
  disconnectFromVehicleError: null,
  vehiclesListQuantity: 0,
  vehiclesStatusLoading: false,
  activeVehicle: null,
  vehiclesStatusError: null,
  engineRunning: false,
  manualControlRunning: false,
  activeKeys: [],
  gamepadRunning: false,
  gamepad: null,
  gamepadIndex: null,
  mapLayerType: 'satellite',
  metricsInterval: 5000,
  listRecordingsLoading: false,
  listRecordings: [],
  listRecordingsError: null,
  listRecordingsTotal: 0,
  deleteRecordingLoading: false,
  deleteRecordingError: null,
  cameraResolution: {},
  listPhotosLoading: false,
  listPhotos: [],
  listPhotosError: null,
  listPhotosTotal: 0,
  listSlidesLoading: false,
  listSlides: [],
  listSlidesError: null,
  listSlidesTotal: 0,
  listArchivesLoading: false,
  listArchives: [],
  listArchivesError: null,
  listArchivesTotal: 0,
  getArchiveLoading: false,
  archive: null,
  getArchiveError: null,
  createArchiveError: null,
  deletePhotosLoading: false,
  deletePhotosError: null,
  pinnedMap: false,
  mapYPosition: 80,
  controller: 'gamepad',
  showSelectController: false,
  mediaList: {
    selectedPhotos: [],
    photosStartDate: null,
    photosEndDate: null,
    photosLimit: 10,
    photosPage: 1,
    selectedSlides: [],
    slidesStartDate: null,
    slidesEndDate: null,
    slidesLimit: 10,
    slidesPage: 1,
    recordingsLimit: 10,
    recordingsPage: 1,
    fullScreenMode: false,
    fullScreenImageIndex: 0,
  },
  selectedUuidDevice: null,
  listMapDragged: false,
  vehicleIsCanConnect: true,
  vehicleIsCanConnectError: null,
  navigateToViewer: false,
  isMissionStarted: false,
  missionText: '',
  camerasState: [],
};

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    clearVehicleIsCanConnect: (state: VehicleState) => {
      state.vehicleIsCanConnect = false;
      state.vehicleIsCanConnectError = null;
    },
    clearMediaList: (state: VehicleState) => {
      state.mediaList.selectedPhotos = [];
      state.mediaList.photosStartDate = null;
      state.mediaList.photosEndDate = null;
      state.mediaList.photosLimit = 10;
      state.mediaList.photosPage = 1;
      state.mediaList.selectedSlides = [];
      state.mediaList.slidesStartDate = null;
      state.mediaList.slidesEndDate = null;
      state.mediaList.slidesLimit = 10;
      state.mediaList.slidesPage = 1;
      state.mediaList.recordingsLimit = 10;
      state.mediaList.recordingsPage = 1;
      state.mediaList.fullScreenMode = false;
      state.mediaList.fullScreenImageIndex = 0;
      state.listRecordings = [];
      state.listSlides = [];
      state.listPhotos = [];
      state.listArchives = [];
      state.listArchivesTotal = 0;
      state.listSlidesTotal = 0;
      state.listPhotosTotal = 0;
      state.listRecordingsTotal = 0;
    },
    setListMapDragged: (state: VehicleState, { payload }) => {
      state.listMapDragged = payload;
    },
    setCameras: (state: VehicleState, { payload }: { payload: boolean[] }) => {
      state.camerasState = payload.map((item) => {
        return {
          cameraState: item,
        };
      });
    },
    updateCameraState: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (state.camerasState[index]) {
        if (state.camerasState[index].cameraState === false) {
          state.camerasState[index].cameraState = true;
        } else {
          state.camerasState[index].cameraState = false;
        }
      }
    },
    setIsMissionStarted: (state: VehicleState, { payload }) => {
      state.isMissionStarted = payload;
    },
    setMissionText: (state: VehicleState, { payload }) => {
      state.missionText = payload;
    },
    setNavigateToViewer: (state: VehicleState, { payload }) => {
      state.navigateToViewer = payload;
    },
    setShowSelectController: (state: VehicleState, { payload }) => {
      state.showSelectController = payload;
    },
    clearArchive: (state: VehicleState) => {
      state.archive = null;
    },
    setFullScreenImageIndex: (state: VehicleState, { payload }) => {
      state.mediaList.fullScreenImageIndex = payload;
    },
    setFullScreenImage: (state: VehicleState) => {
      state.mediaList.fullScreenMode = !state.mediaList.fullScreenMode;
    },
    setController: (state: VehicleState, { payload }) => {
      state.controller = payload;
    },
    setSelectedAllPhotos: (state: VehicleState) => {
      state.mediaList.selectedPhotos = state.listPhotos.map(
        (photo: IStreamingPhoto): ISelectedPhoto => ({
          id: photo.id,
          image: photo.image,
          created_at: photo.created_at,
        })
      );
    },
    setSelectedAllSlides: (state: VehicleState) => {
      state.mediaList.selectedSlides = state.listSlides.map(
        (slide: IStreamingPhoto): ISelectedPhoto => ({
          id: slide.id,
          image: slide.image,
          created_at: slide.created_at,
        })
      );
    },
    setClearAllPhotos: (state: VehicleState) => {
      state.mediaList.selectedPhotos = [];
    },
    setClearAllSlides: (state: VehicleState) => {
      state.mediaList.selectedSlides = [];
    },
    setSelectedPhotos: (
      state: VehicleState,
      { payload }: { payload: ISelectedPhoto }
    ) => {
      if (
        state.mediaList.selectedPhotos.find(
          (selectedPhoto: ISelectedPhoto): boolean =>
            selectedPhoto.id === payload.id
        )
      ) {
        state.mediaList.selectedPhotos = state.mediaList.selectedPhotos.filter(
          (selectedPhoto: ISelectedPhoto): boolean =>
            selectedPhoto.id !== payload.id
        );
      } else {
        state.mediaList.selectedPhotos.push(payload);
      }
    },
    setSelectedSlides: (
      state: VehicleState,
      { payload }: { payload: ISelectedPhoto }
    ) => {
      if (
        state.mediaList.selectedSlides.find(
          (selectedSlide: ISelectedPhoto): boolean =>
            selectedSlide.id === payload.id
        )
      ) {
        state.mediaList.selectedSlides = state.mediaList.selectedSlides.filter(
          (selectedSlide: ISelectedPhoto): boolean =>
            selectedSlide.id !== payload.id
        );
      } else {
        state.mediaList.selectedSlides.push(payload);
      }
    },
    setMediaListRecordingsLimit: (state: VehicleState, { payload }) => {
      state.mediaList.recordingsLimit = payload;
      state.mediaList.recordingsPage = 1;
    },
    setMediaListRecordingsPage: (state: VehicleState, { payload }) => {
      state.mediaList.recordingsPage = payload;
    },
    setMediaListSlidesLimit: (state: VehicleState, { payload }) => {
      state.mediaList.slidesLimit = payload;
      state.mediaList.slidesPage = 1;
    },
    setMediaListSlidesPage: (state: VehicleState, { payload }) => {
      state.mediaList.slidesPage = payload;
    },
    setMediaListPhotosLimit: (state: VehicleState, { payload }) => {
      state.mediaList.photosLimit = payload;
      state.mediaList.photosPage = 1;
    },
    setMediaListPhotosPage: (state: VehicleState, { payload }) => {
      state.mediaList.photosPage = payload;
    },
    setMediaListPhotosStartDate: (state: VehicleState, { payload }) => {
      state.mediaList.photosStartDate = payload;
    },
    setMediaListPhotosEndDate: (state: VehicleState, { payload }) => {
      state.mediaList.photosEndDate = payload;
    },
    setMediaListSlidesStartDate: (state: VehicleState, { payload }) => {
      state.mediaList.slidesStartDate = payload;
    },
    setMediaListSlidesEndDate: (state: VehicleState, { payload }) => {
      state.mediaList.slidesEndDate = payload;
    },
    clearMapPosition: (state: VehicleState) => {
      state.pinnedMap = false;
      state.mapYPosition = 0;
    },
    setMapPosition: (state: VehicleState, { payload }) => {
      state.mapYPosition = payload;
    },
    setPinnedMap: (state: VehicleState, { payload }) => {
      state.pinnedMap = payload;
    },
    setCameraResolution: (state: VehicleState, { payload }) => {
      state.cameraResolution[payload.camera] = payload.resolution;
    },
    setMetricsInterval: (state: VehicleState, { payload }) => {
      state.metricsInterval = payload;
    },
    setLayerType: (state: VehicleState, { payload }) => {
      state.mapLayerType = payload;
    },
    cleanUpVehiclesListPage: (state: VehicleState) => {
      state.vehiclesList = null;
      state.vehiclesListMetrics = null;
      state.vehiclesListQuantity = 0;
    },
    cleanUpVehiclePage: (state: VehicleState) => {
      state.connectToVehicleSuccess = false;
      state.activeVehicle = null;
    },
    setEngineRunning: (state: VehicleState, { payload }) => {
      state.engineRunning = payload;
    },
    setManualControlRunning: (state: VehicleState, { payload }) => {
      state.manualControlRunning = payload;
    },
    setGamepadRunning: (state: VehicleState, { payload }) => {
      state.gamepadRunning = payload;
    },
    setGamepadData: (state: VehicleState, { payload }) => {
      state.gamepad = payload;
    },
    setGamepadIndex: (state: VehicleState, { payload }) => {
      state.gamepadIndex = payload;
    },
    setVehicleConnectionStatus: (state: VehicleState, { payload }) => {
      if (state.activeVehicle) {
        state.activeVehicle.is_online = payload;
      }
    },
    setActiveKey: (state: VehicleState, { payload }) => {
      state.activeKeys = [...new Set(state.activeKeys.concat(payload))];
    },
    removeActiveKey: (state: VehicleState, { payload }) => {
      state.activeKeys = state.activeKeys.filter(
        (key: string): boolean => key !== payload
      );
    },
    clearActiveKeys: (state: VehicleState) => {
      state.activeKeys = [];
    },
    selectDevice: (state: VehicleState, { payload }) => {
      state.selectedUuidDevice = payload;
      state.vehicleIsCanConnect = true;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<VehicleState>) => {
    builder.addCase(getVehicleList.pending, (state: VehicleState) => {
      state.vehiclesListLoading = true;
      state.selectedUuidDevice = null;
    });
    builder.addCase(
      getVehicleList.fulfilled,
      (state: VehicleState, { payload }: { payload: VehiclesListResponse }) => {
        state.vehiclesList = payload.items;
        state.vehiclesListQuantity = payload.count;
        state.vehiclesListLoading = false;
      }
    );
    builder.addCase(
      getVehicleList.rejected,
      (state: VehicleState, { error }) => {
        state.vehiclesListError = error.message as string;
        state.vehiclesListLoading = false;
      }
    );
    builder.addCase(getVehicleListMetrics.pending, (state: VehicleState) => {
      state.vehiclesListMetricsLoading = true;
    });
    builder.addCase(
      getVehicleListMetrics.fulfilled,
      (
        state: VehicleState,
        { payload }: { payload: { items: VehicleMetrics[] } }
      ) => {
        state.vehiclesListMetrics = payload.items.filter(
          (item: VehicleMetrics): boolean => !!item.telemetry
        );
        state.vehiclesListMetricsLoading = false;
      }
    );
    builder.addCase(
      getVehicleListMetrics.rejected,
      (state: VehicleState, { error }) => {
        state.vehiclesListMetricsError = error.message as string;
        state.vehiclesListMetricsLoading = false;
      }
    );
    builder.addCase(getVehicleStatus.pending, (state: VehicleState) => {
      state.vehiclesStatusLoading = true;
    });
    builder.addCase(
      getVehicleStatus.fulfilled,
      (state: VehicleState, { payload }) => {
        state.activeVehicle = payload;
        state.vehiclesStatusLoading = false;
      }
    );
    builder.addCase(
      getVehicleStatus.rejected,
      (state: VehicleState, { error }) => {
        state.vehiclesStatusError = error.message as string;
        state.vehiclesStatusLoading = false;
      }
    );
    builder.addCase(
      vehicleIsCanConnect.fulfilled,
      (state: VehicleState, { payload }: { payload: boolean }) => {
        state.vehicleIsCanConnect = payload;
      }
    );
    builder.addCase(
      vehicleIsCanConnect.rejected,
      (state: VehicleState, { error }) => {
        state.vehicleIsCanConnectError = error.message as string;
      }
    );
    builder.addCase(connectToVehicle.pending, (state: VehicleState) => {
      state.connectToVehicleLoading = true;
    });
    builder.addCase(
      connectToVehicle.fulfilled,
      (state: VehicleState, { payload }: { payload: boolean }) => {
        state.connectToVehicleSuccess = payload;
        state.connectToVehicleLoading = false;
      }
    );
    builder.addCase(
      connectToVehicle.rejected,
      (state: VehicleState, { error }) => {
        state.connectToVehicleError = error.message as string;
        state.connectToVehicleLoading = false;
      }
    );
    builder.addCase(disconnectFromVehicle.pending, (state: VehicleState) => {
      state.disconnectFromVehicleLoading = true;
      state.activeVehicle = null;
    });
    builder.addCase(
      disconnectFromVehicle.fulfilled,
      (state: VehicleState, { payload }: { payload: boolean }) => {
        state.disconnectFromVehicleSuccess = payload;
        state.disconnectFromVehicleLoading = false;
      }
    );
    builder.addCase(
      disconnectFromVehicle.rejected,
      (state: VehicleState, { error }) => {
        state.disconnectFromVehicleError = error.message as string;
        state.disconnectFromVehicleLoading = false;
      }
    );
    builder.addCase(listStreamingRecordings.pending, (state: VehicleState) => {
      state.listRecordingsLoading = true;
      state.listRecordings = [];
    });
    builder.addCase(
      listStreamingRecordings.fulfilled,
      (
        state: VehicleState,
        { payload }: { payload: { items: string[]; count: number } | null }
      ) => {
        state.listRecordingsLoading = false;

        if (payload) {
          state.listRecordings = payload.items;
          state.listRecordingsTotal = payload.count;
        }
      }
    );
    builder.addCase(
      listStreamingRecordings.rejected,
      (state: VehicleState, { error }) => {
        state.listRecordingsError = error.message as string;
        state.listRecordingsLoading = false;
      }
    );
    builder.addCase(deleteStreamingRecording.pending, (state: VehicleState) => {
      state.deleteRecordingLoading = true;
      state.deleteRecordingError = null;
    });
    builder.addCase(
      deleteStreamingRecording.fulfilled,
      (state: VehicleState, { payload }: { payload: string | null }) => {
        state.deleteRecordingLoading = false;

        if (payload) {
          state.listRecordings = state.listRecordings.filter(
            (url: string): boolean => !url.includes(payload)
          );
        }
      }
    );
    builder.addCase(
      deleteStreamingRecording.rejected,
      (state: VehicleState, { error }) => {
        state.deleteRecordingError = error.message as string;
        state.deleteRecordingLoading = false;
      }
    );
    builder.addCase(listStreamingPhotos.pending, (state: VehicleState) => {
      state.listPhotosLoading = true;
      state.listPhotos = [];
    });
    builder.addCase(
      listStreamingPhotos.fulfilled,
      (
        state: VehicleState,
        { payload }: { payload: { items: IStreamingPhoto[]; count: number } }
      ) => {
        state.listPhotosLoading = false;
        state.listPhotos = payload.items;
        state.listPhotosTotal = payload.count;
      }
    );
    builder.addCase(
      listStreamingPhotos.rejected,
      (state: VehicleState, { error }) => {
        state.listPhotosError = error.message as string;
        state.listPhotosLoading = false;
      }
    );
    builder.addCase(listStreamingSlides.pending, (state: VehicleState) => {
      state.listSlidesLoading = true;
      state.listSlides = [];
    });
    builder.addCase(
      listStreamingSlides.fulfilled,
      (
        state: VehicleState,
        { payload }: { payload: { items: IStreamingSlide[]; count: number } }
      ) => {
        state.listSlidesLoading = false;
        state.listSlides = payload.items;
        state.listSlidesTotal = payload.count;
      }
    );
    builder.addCase(
      listStreamingSlides.rejected,
      (state: VehicleState, { error }) => {
        state.listSlidesError = error.message as string;
        state.listSlidesLoading = false;
      }
    );
    builder.addCase(deleteStreamingPhotos.pending, (state: VehicleState) => {
      state.deletePhotosLoading = true;
      state.deletePhotosError = null;
    });
    builder.addCase(
      deleteStreamingPhotos.fulfilled,
      (
        state: VehicleState,
        {
          payload,
        }: {
          payload: { list_ids: number[]; photo_type: 'photo' | 'slide' } | null;
        }
      ) => {
        state.deletePhotosLoading = false;
        if (payload) {
          if (payload.photo_type === 'photo') {
            state.listPhotos = state.listPhotos.filter(
              (photo: IStreamingPhoto): boolean =>
                !payload.list_ids.includes(photo.id)
            );
            state.listPhotosTotal -= payload.list_ids.length;
            state.mediaList.selectedPhotos =
              state.mediaList.selectedPhotos.filter(
                (photo: ISelectedPhoto): boolean =>
                  !payload.list_ids.includes(photo.id)
              );
          } else {
            state.listSlides = state.listSlides.filter(
              (slide: IStreamingPhoto): boolean =>
                !payload.list_ids.includes(slide.id)
            );
            state.listSlidesTotal -= payload.list_ids.length;
            state.mediaList.selectedSlides =
              state.mediaList.selectedSlides.filter(
                (slide: ISelectedPhoto): boolean =>
                  !payload.list_ids.includes(slide.id)
              );
          }
        }
      }
    );
    builder.addCase(
      deleteStreamingPhotos.rejected,
      (state: VehicleState, { error }) => {
        state.deletePhotosError = error.message as string;
        state.deletePhotosLoading = false;
      }
    );
    builder.addCase(createArchive.pending, (state: VehicleState) => {
      state.archive = null;
    });
    builder.addCase(
      createArchive.fulfilled,
      (state: VehicleState, { payload }: { payload: IArchive }) => {
        state.archive = payload;
      }
    );
    builder.addCase(
      createArchive.rejected,
      (state: VehicleState, { error }) => {
        state.createArchiveError = error.message as string;
      }
    );
    builder.addCase(getArchive.pending, (state: VehicleState) => {
      state.archive = null;
    });
    builder.addCase(
      getArchive.fulfilled,
      (state: VehicleState, { payload }: { payload: IArchive }) => {
        state.archive = payload;
      }
    );
    builder.addCase(getArchive.rejected, (state: VehicleState, { error }) => {
      state.createArchiveError = error.message as string;
    });
    builder.addCase(getArchiveList.pending, (state: VehicleState) => {
      state.listArchivesLoading = true;
    });
    builder.addCase(
      getArchiveList.fulfilled,
      (
        state: VehicleState,
        { payload }: { payload: { items: IArchive[]; count: number } }
      ) => {
        state.listArchivesLoading = false;
        state.listArchives = payload.items;
        state.listArchivesTotal = payload.count;
      }
    );
    builder.addCase(
      getArchiveList.rejected,
      (state: VehicleState, { error }) => {
        state.listSlidesError = error.message as string;
        state.listArchivesLoading = false;
      }
    );
  },
});

const vehiclesSliceReducer = vehicleSlice.reducer;
export const {
  setEngineRunning,
  setManualControlRunning,
  setGamepadRunning,
  setGamepadData,
  setGamepadIndex,
  setVehicleConnectionStatus,
  setActiveKey,
  removeActiveKey,
  clearActiveKeys,
  cleanUpVehiclesListPage,
  cleanUpVehiclePage,
  setLayerType,
  setMetricsInterval,
  setCameraResolution,
  setMapPosition,
  clearMapPosition,
  setPinnedMap,
  setMediaListPhotosStartDate,
  setMediaListPhotosEndDate,
  setMediaListSlidesStartDate,
  setMediaListSlidesEndDate,
  setMediaListPhotosLimit,
  setMediaListPhotosPage,
  setShowSelectController,
  setMediaListSlidesLimit,
  setMediaListSlidesPage,
  setMediaListRecordingsLimit,
  setMediaListRecordingsPage,
  setSelectedPhotos,
  setSelectedAllPhotos,
  setClearAllPhotos,
  setFullScreenImage,
  setFullScreenImageIndex,
  selectDevice,
  setSelectedSlides,
  setSelectedAllSlides,
  setClearAllSlides,
  clearArchive,
  clearMediaList,
  setListMapDragged,
  setController,
  clearVehicleIsCanConnect,
  setNavigateToViewer,
  setIsMissionStarted,
  setMissionText,
  setCameras,
  updateCameraState,
} = vehicleSlice.actions;
export default vehiclesSliceReducer;
