import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs';
import Button from '@mui/material/Button';
import { UnknownAction } from '@reduxjs/toolkit';
import VisibilityIcon from '../../../assets/VisibilityIcon';
import CalendarIcon from '../../../assets/CalendarIcon';
import GamepadIcon from '../../../assets/GamepadIcon';
import TextIcon from '../../../assets/TextIcon';
import SelectedVehicleListItem from './SelectedVehicleListItem';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  selectDevice,
  clearVehicleIsCanConnect,
} from '../../../../store/vehicle/vehicle.slice';
import { vehicleIsCanConnect } from '../../../../store/vehicle/vehicle.thunks';
import classes from '../../../styles/vehicles.module.css';
import { Vehicle } from '../../../../domain/vehicle';
import DeviceListMission from '../../Vehicle/Controls/DeviceListMission';

let vehicleIsCanConnectInterval: NodeJS.Timeout;

const DevicePopup: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const selectedUuidDevice = useAppSelector(
    (state) => state.vehicles.selectedUuidDevice?.uuid
  );
  const selectedRealOnlineDevice = useAppSelector(
    (state) => state.vehicles.selectedUuidDevice?.is_real_online
  );
  const selectedOnlineDevice = useAppSelector(
    (state) => state.vehicles.selectedUuidDevice?.is_online
  );

  const vehicleIsCanConnectStatus = useAppSelector(
    (state) => state.vehicles.vehicleIsCanConnect
  );
  const vehiclesList = useAppSelector((state) => state.vehicles.vehiclesList);

  const vehiclesListMetrics = useAppSelector(
    (state) => state.vehicles.vehiclesListMetrics
  );

  const [vehiclesWithMetrics, setVehiclesWithMetrics] = useState<Vehicle[]>([]);
  const [isOpenMissionPopup, setIsOpenMissionPopup] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      clearInterval(vehicleIsCanConnectInterval);
      dispatch(clearVehicleIsCanConnect());
    };
  }, [dispatch]);

  useEffect(() => {
    const updatedVehicles = vehiclesList?.map((vehicle: Vehicle) => {
      const metrics = vehiclesListMetrics?.find(
        (metric: Vehicle) => metric.vehicle_id === vehicle.id
      );
      return {
        ...vehicle,
        longitude: metrics ? metrics.telemetry?.position?.longitude : null,
        latitude: metrics ? metrics.telemetry?.position?.latitude : null,
        is_online: metrics ? metrics.is_online : vehicle.is_online,
        is_real_online: metrics
          ? metrics.is_real_online
          : vehicle.is_real_online,
      };
    });

    setVehiclesWithMetrics(updatedVehicles || []);
  }, [vehiclesList, vehiclesListMetrics]);

  useEffect(() => {
    function getVehicleIsCanConnectStatusInterval() {
      dispatch(
        vehicleIsCanConnect(
          selectedUuidDevice || ''
        ) as unknown as UnknownAction
      );
    }

    if (selectedRealOnlineDevice) {
      getVehicleIsCanConnectStatusInterval();
      vehicleIsCanConnectInterval = setInterval(
        getVehicleIsCanConnectStatusInterval,
        5000
      );
    } else {
      clearInterval(vehicleIsCanConnectInterval);
      dispatch(clearVehicleIsCanConnect());
    }

    return () => clearInterval(vehicleIsCanConnectInterval);
  }, [
    dispatch,
    selectedRealOnlineDevice,
    selectedOnlineDevice,
    selectedUuidDevice,
  ]);

  const setDisabledControl = (
    selectedRealOnlineDevice: boolean,
    vehicleIsCanConnectStatus: boolean,
    selectedOnlineDevice: boolean
  ): boolean => {
    if (
      !selectedRealOnlineDevice &&
      selectedOnlineDevice &&
      !vehicleIsCanConnectStatus
    ) {
      return false;
    }
    if (
      selectedRealOnlineDevice &&
      selectedOnlineDevice &&
      !vehicleIsCanConnectStatus
    ) {
      return true;
    }

    if (vehicleIsCanConnectStatus) {
      return false;
    }

    return true;
  };

  const setDisabledView = (selectedRealOnlineDevice: boolean): boolean => {
    return !selectedRealOnlineDevice;
  };

  const handleClickView = (uuid: string | null, viewType: string): void => {
    if (setDisabledView(selectedRealOnlineDevice)) {
      return;
    }
    navigate(`/vehicles/${uuid}?role=${viewType}`);
  };

  useEffect(() => {
    setDisabledControl(
      selectedRealOnlineDevice,
      vehicleIsCanConnectStatus,
      selectedOnlineDevice
    );
  }, [
    selectedRealOnlineDevice,
    vehicleIsCanConnectStatus,
    selectedOnlineDevice,
  ]);

  const handleClickControl = (uuid: string | null, viewType: string): void => {
    if (
      setDisabledControl(
        selectedRealOnlineDevice,
        vehicleIsCanConnectStatus,
        selectedOnlineDevice
      )
    ) {
      return;
    }
    navigate(`/vehicles/${uuid}?role=${viewType}`);
  };

  const handleClose = (vehicle: string | null) => {
    dispatch(selectDevice(vehicle));
  };

  const selectedDevice = vehiclesWithMetrics?.find(
    (vehicle: Vehicle) => vehicle && vehicle.uuid === selectedUuidDevice
  );

  return (
    <>
      {isOpenMissionPopup ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DeviceListMission
            isOpenMissionPopup={isOpenMissionPopup}
            setIsOpenMissionPopup={setIsOpenMissionPopup}
          />
        </LocalizationProvider>
      ) : null}
      <Box className={classes.devicePopupContainer}>
        {selectedDevice && (
          <SelectedVehicleListItem
            uuid={selectedDevice.uuid}
            name={selectedDevice.name}
            vehicleType={selectedDevice.vehicle_type}
            isOnline={selectedDevice.is_online}
            latitude={selectedDevice.latitude}
            longitude={selectedDevice.longitude}
          />
        )}

        <Box className={classes.devicePopupContainerWrapper}>
          <Box
            onClick={() => handleClose(null)}
            className={classes.devicePopupClose}
          >
            {t('vehiclesList.close')}
          </Box>
          <Box className={classes.devicePopupControls}>
            <Box className={classes.devicePopupIcons}>
              <CalendarIcon />
            </Box>
            <Box className={classes.devicePopupIcons}>
              <GamepadIcon />
            </Box>
            <Box
              onClick={() => setIsOpenMissionPopup(!isOpenMissionPopup)}
              className={classes.devicePopupIcons}
            >
              <TextIcon />
            </Box>
            <Box
              onClick={() =>
                handleClickView(selectedDevice?.uuid || null, 'viewer')
              }
              className={
                setDisabledView(selectedRealOnlineDevice)
                  ? classes.viewerDisabled
                  : classes.devicePopupIcons
              }
            >
              <VisibilityIcon
                disabled={setDisabledView(selectedRealOnlineDevice)}
              />
            </Box>
            <Button
              onClick={() =>
                handleClickControl(selectedDevice?.uuid || null, 'controller')
              }
              disabled={setDisabledControl(
                selectedRealOnlineDevice,
                vehicleIsCanConnectStatus,
                selectedOnlineDevice
              )}
              variant='contained'
              color='primary'
              size='medium'
              className={
                setDisabledControl(
                  selectedRealOnlineDevice,
                  vehicleIsCanConnectStatus,
                  selectedOnlineDevice
                )
                  ? classes.controllerDisabled
                  : ''
              }
            >
              {t('vehiclesList.drive')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DevicePopup;
