import { SUCCESS_STATUS } from '../infra/common/httpService/constants';

export enum VEHICLE_TYPES {
  TRACTOR = 'tractor',
  CAR = 'car',
  BOAT = 'boat',
  DRONE = 'drone',
  TRACTOR_KHTI = 'tractor_khti',
  TRACTOR_GEMINI = 'tractor_gemini',
  PLANE = 'plane',
}

export type Vehicle = {
  vehicle: any;
  attributes: [];
  components: [];
  id: number;
  is_online: boolean;
  vehicle_id: number;
  is_real_online: boolean;
  name: string;
  uuid: string;
  vehicle_type: VEHICLE_TYPES;
  last_metric_time: string;
  change_online_status_at: string;
  longitude: number;
  latitude: number;
};

export type CamerasState = {
  cameraState: boolean;
};

export type VehicleTelemetry = {
  airspeed: null;
  angle_of_rotation: null;
  ardupilot_mission: null;
  ardupilot_mission_step: null;
  attitude: null;
  average_processing_time: null;
  car_gear: string;
  count_cameras: null;
  cameras_data: {
    frame_size: { width: number; height: number };
    real_name: string;
  }[];
  groundspeed: null;
  img: null;
  is_armable: null;
  is_armed: null;
  is_there_cargo: null;
  metrics: null;
  mission_step: null;
  position: { latitude: number; longitude: number; altitude: number };
  wait: null;
};

export type VehicleMetrics = {
  telemetry: VehicleTelemetry;
  telemetry_created_at: Date;
  vehicle: string;
};

export type VehiclesListMetricsResponse = {
  count: number;
  items: VehicleMetrics[];
};

export type VehiclesListResponse = {
  count: number;
  items: Vehicle[];
};

export interface IStreamingPhoto {
  created_at: string;
  id: number;
  image: string;
}

export interface ISelectedPhoto {
  id: number;
  image: string;
  created_at: string;
}

export interface IArchive {
  uuid: string;
  status: string;
  archive_type: 'photo' | 'slide';
  created_at: string;
  updated_at: string;
  reason: string;
  path_to_archive: string;
}

export interface IArchiveList {
  count: number;
  items: IArchive[];
}

export interface IStreamingSlide {
  created_at: string;
  groundspeed: number;
  id: number;
  image: string;
  location: {
    latitude: number;
    longitude: number;
    altitude: number;
  };
  pitch: number;
  roll: number;
  task_id: number;
  yaw: number;
}

export type VehicleRepository = {
  getVehicleListRequest: (
    options: string
  ) => Promise<VehiclesListResponse | unknown>;
  getVehicleListMetricsRequest: (
    options: string
  ) => Promise<VehiclesListMetricsResponse | unknown>;
  connectToVehicleRequest: (
    vehicleUuid: string
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  isVehicleCanConnect: (
    vehicleUuid: string
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  getVehicleStatusRequest: (vehicleUuid: string) => Promise<Vehicle | unknown>;
  disconnectFromVehicleRequest: (
    vehicleUuid: string
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  startStreamingRecordingRequest: (
    vehicleUuid: string,
    num_camera: number
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  stopStreamingRecordingRequest: (
    vehicleUuid: string,
    num_camera: number
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  listStreamingRecordingsRequest: (
    uuid: string,
    limit: number,
    page: number
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  listStreamingPhotosRequest: (
    uuid: string,
    from: any,
    to: any,
    limit: number,
    page: number
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  listStreamingSlidesRequest: (
    uuid: string,
    from: any,
    to: any,
    limit: number,
    page: number
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  deleteStreamingRecordingRequest: (
    vehicleUuid: string,
    names: string
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  deleteStreamingPhotosRequest: (
    uuid: string,
    list_ids: string[],
    photo_type: 'photo' | 'slide'
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  getArchiveRequest: (
    uuid: string
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  getArchivesListRequest: (
    uuid: string,
    limit: number,
    page: number
  ) => Promise<{ status: typeof SUCCESS_STATUS } | unknown>;
  createArchiveRequest(
    archive_type: 'photo' | 'slide',
    list_ids: number[]
  ): Promise<{ status: 'success' } | unknown>;
};

// @ts-ignore
export const vehicleActionsByType: {
  [key in VEHICLE_TYPES]: {
    keyboardAvailable: boolean;
    changeGearAvailable: boolean;
    changeControlAction: boolean;
    gamepadAvailable: boolean;
    steeringWheelAvailable: boolean;
    disarmCommandAvailable: boolean;
    radiomasterAvailable: boolean;
    stopArmCommandAvailable: boolean;
    specialFunctionalityAvailable: boolean;
    armCommand:
      | 'car_arm'
      | 'boat_arm'
      | 'tractor_arm'
      | 'tractor_khti_arm'
      | 'tractor_gemini_arm'
      | 'plane_arm'
      | 'drone_arm'
      | 'arm';
    stopArmCommand:
      | 'tractor_khti_arm_stop'
      | 'car_arm_stop'
      | 'tractor_gemini_arm_stop';
    controlCommand:
      | 'car_controls'
      | 'tractor_controls'
      | 'boat_controls'
      | 'tractor_khti_rotate'
      | 'tractor_gemini_controls';
    resetCommand:
      | 'car_reset_controls'
      | 'tractor_reset_controls'
      | 'drone_reset_controls'
      | 'boat_reset_controls'
      | 'tractor_khti_reset_controls'
      | 'tractor_gemini_reset_controls';
    disarmCommand:
      | 'car_disarm'
      | 'tractor_disarm'
      | 'tractor_gemini_disarm'
      | 'drone_disarm'
      | 'plane_disarm'
      | 'boat_disarm'
      | 'tractor_khti_disarm';
  };
} = {
  car: {
    keyboardAvailable: false,
    gamepadAvailable: true,
    steeringWheelAvailable: true,
    radiomasterAvailable: false,
    disarmCommandAvailable: true,
    specialFunctionalityAvailable: true,
    changeGearAvailable: true,
    changeControlAction: true,
    stopArmCommandAvailable: true,
    armCommand: 'car_arm',
    stopArmCommand: 'car_arm_stop',
    controlCommand: 'car_controls',
    resetCommand: 'car_reset_controls',
    disarmCommand: 'car_disarm',
  },
  tractor: {
    keyboardAvailable: true,
    gamepadAvailable: true,
    steeringWheelAvailable: false,
    disarmCommandAvailable: true,
    radiomasterAvailable: false,
    specialFunctionalityAvailable: false,
    changeGearAvailable: false,
    changeControlAction: true,
    stopArmCommandAvailable: true,
    armCommand: 'tractor_arm',
    stopArmCommand: 'tractor_khti_arm_stop',
    controlCommand: 'tractor_controls',
    resetCommand: 'tractor_reset_controls',
    disarmCommand: 'tractor_disarm',
  },
  boat: {
    keyboardAvailable: false,
    gamepadAvailable: true,
    disarmCommandAvailable: true,
    steeringWheelAvailable: true,
    radiomasterAvailable: true,
    specialFunctionalityAvailable: false,
    changeGearAvailable: true,
    changeControlAction: true,
    stopArmCommandAvailable: false,
    armCommand: 'boat_arm',
    stopArmCommand: 'tractor_khti_arm_stop',
    controlCommand: 'boat_controls',
    resetCommand: 'boat_reset_controls',
    disarmCommand: 'boat_disarm',
  },
  drone: {
    keyboardAvailable: false,
    gamepadAvailable: true,
    disarmCommandAvailable: true,
    steeringWheelAvailable: false,
    radiomasterAvailable: true,
    specialFunctionalityAvailable: true,
    changeGearAvailable: false,
    changeControlAction: true,
    stopArmCommandAvailable: false,
    armCommand: 'drone_arm',
    stopArmCommand: 'tractor_khti_arm_stop',
    controlCommand: 'boat_controls',
    resetCommand: 'drone_reset_controls',
    disarmCommand: 'drone_disarm',
  },
  tractor_khti: {
    keyboardAvailable: false,
    gamepadAvailable: true,
    disarmCommandAvailable: true,
    steeringWheelAvailable: false,
    radiomasterAvailable: false,
    specialFunctionalityAvailable: false,
    changeGearAvailable: true,
    changeControlAction: true,
    stopArmCommandAvailable: true,
    armCommand: 'tractor_khti_arm',
    stopArmCommand: 'tractor_khti_arm_stop',
    controlCommand: 'tractor_khti_rotate',
    resetCommand: 'tractor_khti_reset_controls',
    disarmCommand: 'tractor_khti_disarm',
  },
  tractor_gemini: {
    keyboardAvailable: false,
    gamepadAvailable: true,
    disarmCommandAvailable: true,
    steeringWheelAvailable: true,
    radiomasterAvailable: false,
    specialFunctionalityAvailable: true,
    changeGearAvailable: false,
    changeControlAction: true,
    stopArmCommandAvailable: true,
    armCommand: 'tractor_gemini_arm',
    stopArmCommand: 'tractor_gemini_arm_stop',
    controlCommand: 'tractor_gemini_controls',
    resetCommand: 'tractor_gemini_reset_controls',
    disarmCommand: 'tractor_gemini_disarm',
  },
  plane: {
    keyboardAvailable: false,
    gamepadAvailable: true,
    steeringWheelAvailable: false,
    disarmCommandAvailable: true,
    radiomasterAvailable: true,
    specialFunctionalityAvailable: true,
    changeGearAvailable: false,
    changeControlAction: true,
    stopArmCommandAvailable: false,
    armCommand: 'plane_arm',
    stopArmCommand: 'tractor_khti_arm_stop',
    controlCommand: 'boat_controls',
    resetCommand: 'drone_reset_controls',
    disarmCommand: 'plane_disarm',
  },
};
