import { FC, memo, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import adapter from 'webrtc-adapter';
import { UnknownAction } from '@reduxjs/toolkit';
import Janus, {
  JSEP,
  Message,
  PluginHandle,
} from '../../../../infra/common/janusService';
import {
  ATTACHED_VIDEO_PLUGIN_STATUS,
  iceServers,
  janusConfig,
  max_poll_events,
  PARTICIPANTS_DATA_STATUS,
  RESTART_TIMEOUT,
  VIDEO_ROOM_PLUGIN,
  STOP_STREAM_TIMEOUT,
} from '../../../../infra/common/janusService/constants';
import {
  createOpaqueID,
  leaveMessage,
  pauseMessage,
  setCreateAnswerSchema,
  setJoinMessage,
  setListParticipantsMessage,
  startMessage,
} from '../../../../infra/common/janusService/utils';
import {
  IParticipant,
  IParticipantsData,
} from '../../../../infra/common/janusService/types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { socketSend } from '../../../../infra/common/socketService';
import {
  startVideoStreamingCommand,
  stopVideoStreamingCommand,
  takePhotoCommand,
} from '../../../../infra/common/socketService/commands/streamingCommands';
import {
  startSingleFrameModeSchema,
  stopSingleFrameModeSchema,
} from '../../../../infra/common/socketService/commands/singleFrameModeCommands';
import { clearVehicleImage } from '../../../../store/socket/socket.slice';
import CommandsControls from './CommandsControls';
import LoadingStatus from './LoadingStatus';
import LayoutControls from './LayoutControls';
import FullScreenCameras from './FullScreenCameras';
import SlideStreamingControls from './SlideStreamingControls';
import { stopStreamingRecord } from '../../../../store/vehicle/vehicle.thunks';
import VideoRecordingInfo from './VideoRecordingInfo';
import {
  setIconControlsStyles,
  setSingleVideoStreamOutline,
  setSingleVideoStreamVisibility,
  setStylesForSingleVideoStreamContainer,
} from '../../../common/helpers/vehicleStyles';
import classes from '../../../styles/stream.module.css';
import { updateCameraState } from '../../../../store/vehicle/vehicle.slice';
import { CamerasState } from '../../../../domain/vehicle';

const timers: { [key: string]: NodeJS.Timeout } = {};
const bitrateIntervals: { [key: string]: NodeJS.Timeout } = {};
const reconnectTimeouts: { [key: string]: NodeJS.Timeout } = {};

interface IProps {
  camera: number;
  totalCameras: number;
  pinnedCamera: number | null;
  setPinnedCamera: (camera: number | null) => void;
  videoFullScreenCamera: number | null;
  setVideoFullScreenCamera: (camera: number | null) => void;
  totalThreeElements: boolean;
}

const VideoStream: FC<IProps> = memo(
  ({
    camera,
    setPinnedCamera,
    pinnedCamera,
    videoFullScreenCamera,
    setVideoFullScreenCamera,
    totalCameras,
    totalThreeElements,
  }): ReactElement | null => {
    const dispatch = useAppDispatch();
    const roomID = useAppSelector((state) => state.vehicles.activeVehicle?.id);
    const vehicleType = useAppSelector(
      (state) => state.vehicles.activeVehicle?.vehicle_type
    );
    const isDeviceOnline = useAppSelector(
      (state) => state.vehicles.activeVehicle?.is_online
    );
    const uuid = useAppSelector((state) => state.vehicles.activeVehicle?.uuid);
    const pinnedMap = useAppSelector((state) => state.vehicles.pinnedMap);
    const isUserOnline = useAppSelector((state) => state.app.isUserOnline);
    const getPingSuccess = useAppSelector((state) => state.app.getPingSuccess);
    const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
    const vehicleImage = useAppSelector(
      (state) => state.socket.vehicleImages[camera]
    );

    const socketStatus = useAppSelector((state) => state.socket.socketStatus);
    const camerasState = useAppSelector((state) => state.vehicles.camerasState);

    const [videoInitializing, setVideoInitializing] = useState<boolean>(false);
    const [videoFullScreenMode, setVideoFullScreenMode] =
      useState<boolean>(false);
    const [slidesLoading, setSlidesLoading] = useState<boolean>(false);
    const [videoHovered, setVideoHovered] = useState<boolean>(false);
    const [videoStarted, setVideoStarted] = useState<boolean>(false);
    const [videoStopped, setVideoStopped] = useState<boolean>(false);
    const [videoPaused, setVideoPaused] = useState<boolean>(false);
    const [videoRestarted, setVideoRestarted] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const [janus, setJanus] = useState<Janus | null>(null);
    const [isCanReconnect, setIsCanReconnect] = useState<boolean>(false);
    const [streaming, setStreaming] = useState<PluginHandle | null>(null);
    const [streamExisted, setStreamExisted] = useState<boolean>(false);
    const [playStreamDisabled, setPlayStreamDisabled] =
      useState<boolean>(false);
    const [stopStreamDisabled, setStopStreamDisabled] =
      useState<boolean>(false);
    const collapseAdditionalInfoSection = useAppSelector(
      (state) => state.app.collapseAdditionalInfoSection
    );
    const [smallTimeout, setSmallTimeout] = useState<boolean>(false);
    const [isCleanUpEnd, setIsCleanUpEnd] = useState<boolean>(false);
    const lastExecutionTimeRef = useRef<number>(0);

    const [videoRecordingLoading, setVideoRecordingLoading] =
      useState<boolean>(false);
    const [videoRecording, setVideoRecording] = useState<boolean>(false);
    const [videoSaved, setVideoSaved] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement>(null);
    const { t } = useTranslation();

    const currentTime = Date.now();

    useEffect(() => {
      if (vehicleImage) {
        dispatch(clearVehicleImage(camera));
      }

      return () => {
        clearInterval(timers[camera]);
        clearInterval(bitrateIntervals[camera]);
        clearTimeout(reconnectTimeouts[camera]);
        stopVideoStreaming(camera);
        dispatch(clearVehicleImage(camera));

        if (vehicleImage) {
          socketSend(stopSingleFrameModeSchema(camera), timeProcessing);
        }
      };
    }, []);

    useEffect(() => {
      if (stopStreamDisabled && !isCanReconnect) {
        setTimeout(() => {
          setStopStreamDisabled(false);
          clearInterval(bitrateIntervals[camera]);
          clearTimeout(reconnectTimeouts[camera]);
        }, STOP_STREAM_TIMEOUT);
      }
    }, [stopStreamDisabled, isCanReconnect]);

    useEffect(() => {
      if (playStreamDisabled) {
        setTimeout(
          () => {
            setPlayStreamDisabled(false);
            setSmallTimeout(false);
            clearInterval(bitrateIntervals[camera]);
            clearTimeout(reconnectTimeouts[camera]);
          },
          smallTimeout ? 500 : RESTART_TIMEOUT
        );
      }
    }, [playStreamDisabled, isCanReconnect, smallTimeout]);

    // useEffect(() => {
    //   if (stopStreamDisabled && isCanReconnect) {
    //     setTimeout(() => {
    //       setPlayStreamDisabled(false);
    //       clearInterval(bitrateIntervals[camera]);
    //       clearTimeout(reconnectTimeouts[camera]);
    //     }, 1000);
    //   }
    // }, [stopStreamDisabled, isCanReconnect]);

    // useEffect(() => {
    //   if (playStreamDisabled && isCanReconnect) {
    //     setPlayStreamDisabled(false);
    //     clearInterval(bitrateIntervals[camera]);
    //     clearTimeout(reconnectTimeouts[camera]);
    //   }
    // }, [stopStreamDisabled, isCanReconnect]);

    useEffect(() => {
      if (slidesLoading) {
        if (videoInitializing || vehicleImage) {
          setSlidesLoading(false);
          setLoadingMessage('');
        }
      }
    }, [slidesLoading, videoInitializing, vehicleImage]);

    useEffect(() => {
      if (streamExisted && isUserOnline && isDeviceOnline && getPingSuccess) {
        setStreamExisted(false);
        stopVideoStreaming(camera);

        // TODO: will remove it later
        // reconnectTimeouts[camera] = setTimeout(async () => {
        //   await startVideoStreaming(camera, playStreamDisabled);
        // }, RESTART_TIMEOUT);
      }
    }, [isUserOnline, streamExisted, isDeviceOnline, getPingSuccess]);

    useEffect(() => {
      if (videoFullScreenCamera !== null && videoFullScreenCamera !== camera) {
        if (videoStarted) {
          pauseVideoStreaming();
        }
      } else if (videoPaused) {
        restartVideoStreaming();
      }
    }, [videoFullScreenCamera, videoFullScreenMode]);

    // useEffect(() => {
    //   if (streaming && videoStarted) {
    //     bitrateIntervals[camera] = setInterval(() => {
    //       setBitrate(streaming.getBitrate());
    //     }, BITRATE_TIMEOUT);
    //   } else {
    //     clearInterval(bitrateIntervals[camera]);
    //     setBitrate('0 kbits/sec');
    //   }
    // }, [streaming, videoStarted]);

    useEffect(() => {
      if (videoSaved) {
        setTimeout(() => {
          setVideoSaved(false);
        }, 3000);
      }
    }, [videoSaved]);

    const cleanUpStates = (): void => {
      setJanus(null);
      setStreaming(null);
      clearInterval(timers[camera]);
      clearTimeout(reconnectTimeouts[camera]);
      setVideoStopped(true);
      setLoadingMessage('');
      setVideoInitializing(false);
      setVideoStarted(false);
      setVideoPaused(false);
      setIsCleanUpEnd(true);

      if (vehicleImage) {
        dispatch(clearVehicleImage(camera));
        socketSend(stopSingleFrameModeSchema(camera), timeProcessing);
      }
    };

    useEffect(() => {
      if (socketStatus === 'disconnected') {
        setIsCanReconnect(true);
        lastExecutionTimeRef.current = currentTime;
      }
    }, [socketStatus]);

    useEffect(() => {
      if (socketStatus === 'connected' && isCanReconnect) {
        console.log(currentTime - lastExecutionTimeRef.current);
        if (
          lastExecutionTimeRef.current !== null &&
          currentTime - lastExecutionTimeRef.current >= 20000
        ) {
          if (camerasState[camera].cameraState) {
            setSmallTimeout(true);
            stopVideoStreaming(camera);
          }
        }
      }
    }, [socketStatus, camerasState[camera], isCanReconnect]);

    useEffect(() => {
      if (!playStreamDisabled && isCanReconnect && isCleanUpEnd) {
        if (camerasState[camera].cameraState) {
          startVideoStreaming(camera, playStreamDisabled, stopStreamDisabled);
          setIsCleanUpEnd(false);
        }
      }
    }, [playStreamDisabled, isCanReconnect, isCleanUpEnd]);

    const startVideoStreaming = async (
      camera: number,
      playStreamDisabled: boolean,
      stopStreamDisabled: boolean
    ) => {
      if (camerasState[camera].cameraState === false) {
        dispatch(updateCameraState(camera));
      }

      if (playStreamDisabled) {
        return;
      }

      if (isCanReconnect) {
        setIsCanReconnect(false);
      }

      if (!stopStreamDisabled) {
        await setStopStreamDisabled(true);
      }

      await socketSend(stopSingleFrameModeSchema(camera), timeProcessing);
      setTimeout(async () => {
        await dispatch(clearVehicleImage(camera));
      }, 2000);
      await setVideoInitializing(true);
      await socketSend(startVideoStreamingCommand(camera), timeProcessing);
      await Janus.init({
        debug: true,
        dependencies: Janus.setDefaultDependencies({ adapter }),
      });

      let localStream: PluginHandle | null = null;
      const janus = new Janus({
        server: janusConfig.url as string,
        apisecret: janusConfig.apiSecret,
        iceServers,
        max_poll_events,
        success: (): void => {
          janus.attach({
            plugin: VIDEO_ROOM_PLUGIN,
            opaqueId: createOpaqueID(),
            success: (pluginHandle: PluginHandle): void => {
              localStream = pluginHandle;
              setStreaming(localStream);
              setVideoRestarted(false);
              setLoadingMessage(t('vehicle.configurationCompletedLabel'));

              const getParticipants = (): void => {
                (localStream as PluginHandle).send({
                  ...setListParticipantsMessage(roomID),
                  success: (participantsData: IParticipantsData): void => {
                    if (
                      participantsData.videoroom === PARTICIPANTS_DATA_STATUS &&
                      participantsData.participants.length
                    ) {
                      const activeParticipant: IParticipant | undefined =
                        participantsData.participants.find(
                          (participant: IParticipant): boolean =>
                            participant.publisher &&
                            Number(participant.display) === camera
                        );

                      if (activeParticipant) {
                        console.log(activeParticipant, 'activeParticipant');
                        setLoadingMessage(t('vehicle.startStreamingLabel'));
                        (localStream as PluginHandle).send({
                          ...setJoinMessage(roomID, activeParticipant.id),
                        });
                        console.log(camera);
                        clearInterval(timers[camera]);
                      } else {
                        setLoadingMessage(t('vehicle.connectionCameraLabel'));
                      }
                    } else if (
                      participantsData?.error_code === 426 &&
                      participantsData.error?.startsWith('No such room')
                    ) {
                      setLoadingMessage(
                        `${t('vehicle.noConnectToRoom')} ${roomID}`
                      );
                    } else {
                      setLoadingMessage(t('vehicle.searchCamerasLabel'));
                    }
                  },
                });
              };

              timers[camera] = setInterval((): void => getParticipants(), 1000);
            },
            onmessage: (message: Message, jsep: JSEP | undefined): void => {
              if (message.videoroom === ATTACHED_VIDEO_PLUGIN_STATUS && jsep) {
                (localStream as PluginHandle).createAnswer({
                  ...setCreateAnswerSchema(jsep?.sdp, jsep?.type),
                  success: (jsep: JSEP) => {
                    (localStream as PluginHandle).send({
                      ...startMessage,
                      jsep,
                    });
                    setVideoStarted(true);
                  },
                });
              }
            },
            onremotetrack: (track: MediaStreamTrack): void => {
              const stream: MediaStream = new MediaStream([track]);
              setLoadingMessage('');
              setVideoInitializing(false);
              if (videoRef && videoRef.current) {
                Janus.attachMediaStream(videoRef.current, stream);
              }
            },
          });
        },
        error: async (): Promise<void> => {
          setStreamExisted(true);
        },
        destroyed: (): void => {
          if (streaming) {
            streaming.send({ ...leaveMessage });
            streaming.hangup();
          }
        },
      });

      setJanus(janus);
    };

    const stopVideoStreaming = async (camera: number): Promise<void> => {
      if (stopStreamDisabled) {
        return;
      }

      await socketSend(stopVideoStreamingCommand(camera), timeProcessing);

      if (!playStreamDisabled) {
        await setPlayStreamDisabled(true);
      }
      if (janus) {
        janus.destroy({
          notifyDestroyed: true,
          success: async () => {
            cleanUpStates();
            if (videoRecording) {
              await dispatch(
                stopStreamingRecord({
                  uuid,
                  camera,
                  onSuccess: () => {
                    setVideoRecording(false);
                    setVideoRecordingLoading(false);
                  },
                } as any) as unknown as UnknownAction
              );
            }
          },
        });
      }
    };

    const restartVideoStreaming = async (): Promise<void> => {
      if (streaming) {
        await streaming.send({ ...startMessage });
      }

      setVideoInitializing(false);
      setVideoPaused(false);
      setVideoStopped(false);
      setVideoStarted(true);
    };

    const pauseVideoStreaming = async (): Promise<void> => {
      if (streaming) {
        await streaming.send({
          ...pauseMessage,
        });
      }

      if (videoRecording) {
        await dispatch(
          stopStreamingRecord({
            uuid,
            camera,
            onSuccess: () => {
              setVideoRecording(false);
              setVideoRecordingLoading(false);
            },
          } as any) as unknown as UnknownAction
        );
      }

      setVideoInitializing(false);
      setVideoStarted(false);
      setVideoStopped(false);
      setVideoPaused(true);
    };

    const startStopSlideStreaming = async (
      image: string | null
    ): Promise<void> => {
      await setSlidesLoading(true);
      await setLoadingMessage(t('vehicle.slidesLoadingLabel'));
      if (videoRecording) {
        await dispatch(
          stopStreamingRecord({
            uuid,
            camera,
            onSuccess: () => {
              setVideoRecording(false);
              setVideoRecordingLoading(false);
            },
          } as any) as unknown as UnknownAction
        );
      }

      if (janus && streaming) {
        janus.destroy({
          notifyDestroyed: true,
          success: async () => {
            cleanUpStates();
            await socketSend(
              startSingleFrameModeSchema(camera),
              timeProcessing
            );
          },
        });
        return;
      }

      if (image) {
        await socketSend(stopSingleFrameModeSchema(camera), timeProcessing);
        setTimeout(async () => {
          await dispatch(clearVehicleImage(camera));
          await setSlidesLoading(false);
          await setLoadingMessage('');
        }, 2000);
        return;
      }

      await socketSend(startSingleFrameModeSchema(camera), timeProcessing);
    };

    const takePhoto = async (): Promise<void> => {
      await socketSend(takePhotoCommand(camera), timeProcessing);
    };

    return (
      <Box
        className={`${classes.videoWrapper} ${totalThreeElements && pinnedCamera === null ? classes.thirdElement : ''}`}
        style={{
          ...setStylesForSingleVideoStreamContainer(
            videoFullScreenCamera === camera,
            pinnedCamera,
            pinnedCamera === camera,
            collapseAdditionalInfoSection,
            totalCameras,
            pinnedMap
          ),
          ...setSingleVideoStreamOutline(
            videoHovered,
            totalCameras,
            videoFullScreenCamera !== camera
          ),
        }}
        onMouseEnter={() => setVideoHovered(true)}
        onMouseLeave={() => setVideoHovered(false)}
      >
        {vehicleImage ? (
          <img
            src={`data:image/jpeg;base64,${vehicleImage}` as string}
            alt='Crop'
            className={classes.videoStreamMedia}
          />
        ) : (
          <video
            autoPlay
            muted
            playsInline
            ref={videoRef}
            className={classes.videoStreamMedia}
            style={setSingleVideoStreamVisibility(videoStarted)}
          />
        )}
        {videoRecording || videoSaved ? (
          <VideoRecordingInfo
            camera={camera}
            videoSaved={videoSaved}
            fullScreen={videoFullScreenCamera === camera}
          />
        ) : null}
        {videoHovered && totalCameras > 1 ? (
          <LayoutControls
            setVideoFullScreenCamera={setVideoFullScreenCamera}
            videoFullScreenCamera={videoFullScreenCamera}
            setVideoHovered={setVideoHovered}
            pinnedCamera={pinnedCamera}
            setPinnedCamera={setPinnedCamera}
            setVideoFullScreenMode={setVideoFullScreenMode}
            camera={camera}
            pinnedMap={pinnedMap}
          />
        ) : null}
        {videoFullScreenCamera === camera &&
        totalCameras > 1 &&
        videoHovered ? (
          <FullScreenCameras
            camera={camera}
            totalCameras={totalCameras}
            setVideoFullScreenCamera={setVideoFullScreenCamera}
            setVideoFullScreenMode={setVideoFullScreenMode}
          />
        ) : null}
        {!videoStarted ? (
          <Box className={classes.loadingStatusContainer}>
            <LoadingStatus
              loadingMessage={loadingMessage}
              videoInitializing={videoInitializing}
              videoRestarted={videoRestarted}
              videoStarted={videoStarted}
              videoPaused={videoPaused}
              imageAvailable={!!vehicleImage}
              camera={camera}
              slidesLoading={slidesLoading}
            />
          </Box>
        ) : null}
        {videoHovered ? (
          <Box
            className={classes.iconControls}
            style={setIconControlsStyles(pinnedMap || pinnedCamera !== null)}
          >
            {/* <SlideStreamingControls
              image={vehicleImage}
              videoRestarted={videoRestarted}
              vehicleType={vehicleType}
              startStopSlideStreaming={startStopSlideStreaming}
              reduceButton={pinnedMap || pinnedCamera !== camera}
              slidesLoading={slidesLoading}
            /> */}
            <CommandsControls
              camera={camera}
              uuid={uuid}
              streaming={streaming}
              videoRecording={videoRecording}
              videoStopped={videoStopped}
              videoInitializing={videoInitializing}
              videoRestarted={videoRestarted}
              videoStarted={videoStarted}
              startVideoStreaming={startVideoStreaming}
              stopVideoStreaming={stopVideoStreaming}
              setVideoInitializing={setVideoInitializing}
              playStreamDisabled={playStreamDisabled}
              stopStreamDisabled={stopStreamDisabled}
              setVideoRecording={setVideoRecording}
              videoRecordingLoading={videoRecordingLoading}
              setVideoRecordingLoading={setVideoRecordingLoading}
              takePhoto={takePhoto}
              setVideoSaved={setVideoSaved}
              reduceButton={pinnedMap || pinnedCamera !== camera}
            />
          </Box>
        ) : null}
      </Box>
    );
  }
);

export default VideoStream;
